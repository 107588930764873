body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-alert.ant-alert-no-icon {
  padding: 6px 12px !important;
}

.ant-alert {
  font-size: 13px !important;
}

.ant-card-body {
  padding: 5px 10px !important;
}

.ant-card-head {
  padding: 0 10px !important;
  min-height: auto !important;
}

.ant-card-actions > li {
  margin: 5px 0 !important;
}

.ant-card-head-title {
  padding: 5px 0 !important;
}

.ant-card-extra {
  padding: 5px 0 !important;
}

.Loading {
  text-align: center;
  padding: 2rem;
}

/* Override antd error modal styles */
.errorModalWrapper {
  width: 100%;
  top: 30vh;
  margin-left: auto;
  margin-right: auto;
}

.errorModalWrapper .ant-modal-body {
  padding: 2rem;
}

.errorModalWrapper [ant-click-animating-without-extra-node='true']::after {
  --antd-wave-shadow-color: #fff;
}

.errorModalWrapper .ant-modal-content {
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(254, 204, 189, 0.4);
}

.errorModalWrapper .ant-modal-confirm-body-wrapper {
  border: 1px solid #feccbd;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.errorModalWrapper .ant-modal-confirm-content {
  color: #d64423;
  text-align: center;
  margin: 3rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.errorModalWrapper .ant-btn {
  border: none;
  background: #fff;
  color: #d64423;
  font-weight: normal;
  font-size: 1.2rem;
  box-shadow: none;
  outline: 0;
}

.errorModalWrapper .ant-modal-confirm-btns {
  clear: both;
  margin-bottom: -1.2rem;
  text-align: center;
  margin-top: 0;
}

.errorModalWrapper .ant-btn-primary:hover {
  border: none;
  background: #fff;
  color: #d64423;
  outline: 0;
  box-shadow: none;
}

.errorModalWrapper .ant-btn-primary:focus {
  border: none;
  background: #fff;
  color: #d64423;
  outline: 0;
  box-shadow: none;
}

.errorModalWrapper .ant-btn-primary:active {
  border: none;
  background: #fff;
  color: #d64423;
  outline: 0;
  box-shadow: none;
}
/* End */

.errorBoundaryWrapper {
  text-align: center;
  padding-top: 15vh;
  font-size: 1.2rem;
  background: #fef5dd;
  color: #806538;
  font-weight: 500;
  height: 100vh;
  line-height: 2;
}

.errorBoundaryImgWrapper {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorBoundaryImg {
  width: 60%;
  margin: 40px 0;
}

.modalMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999999;
}

.modalContainer {
  position: fixed;
  top: 35%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  padding: 18px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  text-align: center;
}

.advancedSearchWrapper {
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 20px;
}

.advancedSearchWrapper .ant-form-item {
  display: flex;
}

.advancedSearchWrapper .ant-form-item-control-wrapper {
  flex: 1;
}

.ptr-element {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  color: #aaa;
  z-index: 10;
  text-align: center;
  height: 50px;
}

.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  transition: all 0.25s ease;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 5px;
}
.ptr-refresh .ptr-element .genericon {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  display: inline-block;
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
}
.ptr-loading .loading {
  display: block;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  border-radius: 50%;
  -webkit-animation: ptr-loading 0.4s infinite alternate;
  animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
  -webkit-animation-delay: 0;
  animation-delay: 0 !important;
}

.loading-ptr-2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s !important;
}

@-webkit-keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}

@keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  -webkit-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
}
